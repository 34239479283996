import 'cookie-store';

export enum CookieConsentCategory {
    Advertising = '4',
    Functional = '3',
    Required = '1',
    Unknown = 'unknown',
}

export function isConsentCategoryGranted(category: CookieConsentCategory): boolean {
    return window.OnetrustActiveGroups?.split(',').indexOf(category) !== -1;
}

export function getCookie(name: string): string {
    const cookieString = '; ' + document.cookie;
    const parts = cookieString.split('; ' + name + '=');
    if (parts.length === 2) {
        // @ts-expect-error * Automated Type Error Fix
        return parts.pop().split(';').shift();
    }
    // @ts-expect-error * Automated Type Error Fix
    return null;
}

export function getCookieConsented(name: string, category: CookieConsentCategory): string {
    if (isConsentCategoryGranted(category)) {
        return getCookie(name);
    }
    // @ts-expect-error * Automated Type Error Fix
    return null;
}

/**
 * This method only saves cookies if the category is allowed to be saved based on the user's cookie consent settings.
 * @param name Cookie name
 * @param value Cookie value
 * @param category category of this cookie (required, functional, advertising)
 * @param days expiry time (in days) for this cookie
 */
export function createCatCookie(
    name: string,
    value: string,
    category: CookieConsentCategory,
    days?: number,
    expirationTimestamp?: number,
): void {
    if (isConsentCategoryGranted(category)) {
        createCookie(name, value, days, expirationTimestamp);
    }
}

export function createCookie(name: string, value: string, days?: number, expirationTimestamp?: number): void {
    let expires: string;
    const date = new Date();
    if (days) {
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = `; expires=${date.toUTCString()}`;
    } else if (expirationTimestamp) {
        expires = `; expires=${new Date(+expirationTimestamp).toUTCString()}`;
    } else {
        expires = '';
    }
    document.cookie = `${name}=${value}${expires}; path=/`;
}

export async function getCookieExpirationTimestamp(name: string) {
    const cookieInfo = await window.cookieStore.get(name);
    return cookieInfo?.expires;
}

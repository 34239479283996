import { DeepReadonly } from 'ts-essentials';

export function emptyObject<T = AnyObject>(): T {
    return Object.create(null);
}

export function isObject(object: unknown): object is AnyObject {
    return Object.prototype.toString.call(object) === '[object Object]';
}

export function isNull<T>(value: T | null): value is null {
    return value == null;
}

export function isNotNull<T>(value: T | null): value is T {
    return value != null;
}

function isInArray(key: string, matches: string[]) {
    return matches.includes(key);
}

function stringsMatch(key: string, match: string) {
    return key === match;
}

export function keyExists(obj: unknown, keys: string | string[]) {
    if (!obj || typeof obj !== 'object') {
        return false;
    }

    if (Array.isArray(obj)) {
        for (const item of obj) {
            if (keyExists(item, keys)) {
                return true;
            }
        }
        return false;
    }

    const matchFn = Array.isArray(keys) ? isInArray : stringsMatch;

    for (const key of Object.keys(obj)) {
        // We already did type checking in the line above, adding more checks here would cause performance degradation just to appease TS compiler
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (matchFn(key, keys as any) || keyExists((obj as any)[key], keys)) {
            return true;
        }
    }

    return false;
}

export function parseJson<T = Record<string, unknown>>(str: string): T {
    return JSON.parse(str);
}

/**
 * Clones an object and returns a new one with its keys sorted.
 *
 * @param {Record<string, unknown>} object
 * @returns {Record<string, unknown>}
 */
export function sortObjectKeys(object: AnyObject, compareFn?: (a: string, b: string) => number): AnyObject {
    return Object.keys(object)
        .sort(compareFn)
        .reduce((obj: AnyObject, key) => {
            obj[key] = object[key];
            return obj;
        }, {});
}

// IMPORTANT: This deepEquals function is only appropriate to use on JSON objects (without Date or RegExp values)
// Largely based on the dequal package by lukeed, minus Date and RegExp capabilities: https://www.npmjs.com/package/dequal
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function deepEquals(foo: any, bar: any): boolean {
    let ctor, len: number;
    if (foo === bar) {
        return true;
    }

    if (foo && bar && (ctor = foo.constructor) === bar.constructor) {
        if (ctor === Array) {
            if ((len = foo.length) === bar.length) {
                // eslint-disable-next-line no-empty
                while (len-- && deepEquals(foo[len], bar[len])) {}
            }
            return len === -1;
        }

        if (!ctor || typeof foo === 'object') {
            len = 0;
            // eslint-disable-next-line guard-for-in
            for (ctor in foo) {
                if (
                    Object.prototype.hasOwnProperty.call(foo, ctor) &&
                    ++len &&
                    !Object.prototype.hasOwnProperty.call(bar, ctor)
                ) {
                    return false;
                }
                if (!(ctor in bar) || !deepEquals(foo[ctor], bar[ctor])) {
                    return false;
                }
            }
            return Object.keys(bar).length === len;
        }
    }

    // eslint-disable-next-line no-self-compare
    return foo !== foo && bar !== bar;
}

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/freeze#what_is_shallow_freeze
export function deepFreeze<T extends object>(object: T): DeepReadonly<T> {
    const propNames = Reflect.ownKeys(object);

    for (const name of propNames) {
        const value = (object as Record<string | symbol, unknown>)[name];
        const type = typeof value;
        if ((value && type === 'object') || type === 'function') {
            deepFreeze(value as object);
        }
    }

    return Object.freeze(object) as DeepReadonly<T>;
}

import { AxiosError, AxiosResponse } from 'axios';
import { CORRELATION_ID_PARAM, logger, randomUUID } from '~/modules/utilities/cross_env_utils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function handleNetworkWarning(error: AxiosError<{ error?: string | string[]; message?: unknown }>): any {
    const correlationId = error.config?.headers?.[CORRELATION_ID_PARAM] ?? randomUUID();
    if (error.response) {
        const code = error.response.status;
        logger.warn(`[${correlationId}]_NETWORK_WARNING_[${code}]: ${error.response.data.message}`);
        // TODO: Display a warning toast (maybe?)
    } else {
        logger.warn(`[${correlationId}]_NETWORK_WARNING: ${error.message}`);
        // TODO: Display a warning toast (maybe?)
    }
}

export function selectAxiosData<T = unknown>(axiosResponse?: AxiosResponse) {
    return axiosResponse?.data as T;
}

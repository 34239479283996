export const DUMMY_UUID = '00000000-0000-0000-0000-000000000000';

// The following logic is copied from 'uuid' source code: https://github.com/uuidjs/uuid
export function validateUUID(uuid: unknown) {
    return (
        typeof uuid === 'string' &&
        /^(?:[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}|00000000-0000-0000-0000-000000000000)$/i.test(
            uuid,
        )
    );
}

// From the HTML5 spec: https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
export function validateEmail(email: unknown) {
    return (
        typeof email === 'string' &&
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            email,
        )
    );
}

// From Mozilla spec: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toISOString
export function validateISOTimestamp(timestamp: unknown) {
    return (
        typeof timestamp === 'string' && /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/i.test(timestamp)
    );
}

export function validateUrl(url: unknown) {
    if (typeof url !== 'string') {
        return false;
    }
    try {
        // eslint-disable-next-line no-new
        new URL(url);
        return true;
    } catch {
        return false;
    }
}

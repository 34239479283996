import axios from 'axios';

if (clientConfig.ENABLE_AXIOS_CREDENTIALS) {
    // Enable cookie passing to API requests
    axios.defaults.withCredentials = true;
}

// Client configs (injected by the server, is a result of calling build_bundled_config.ts)
export const VERSION = clientConfig.VERSION;
export const NODE_ENV = clientConfig.NODE_ENV;
export const DEFAULT_LANGUAGE = clientConfig.DEFAULT_LANGUAGE;
export const LOGGER_PREFIX = clientConfig.LOGGER_PREFIX;
export const LOGGER_SOURCE = clientConfig.LOGGER_SOURCE;
export const LOGGER_WSS_ENDPOINT = clientConfig.LOGGER_WSS_ENDPOINT;
export const APPX_ORG_API_URL = clientConfig.APPX_ORG_API_URL;
export const ENABLE_AXIOS_CREDENTIALS = clientConfig.ENABLE_AXIOS_CREDENTIALS;
export const USE_LEGACY_SID = clientConfig.USE_LEGACY_SID;
export const LEGACY_LOGIN_URL = clientConfig.LEGACY_LOGIN_URL;
export const LEGACY_SIGNUP_URL = clientConfig.LEGACY_SIGNUP_URL;
export const PUBLIC_URL = clientConfig.PUBLIC_URL;
export const APPX_SITE_URL = clientConfig.APPX_SITE_URL;
export const APPX_JP_SITE_URL = clientConfig.APPX_JP_SITE_URL;
export const APPX_SITE_API_URL = clientConfig.APPX_SITE_API_URL;
export const APPX_SITE_API_VERSION = clientConfig.APPX_SITE_API_VERSION;
export const TBID_URL = clientConfig.TBID_URL;
export const TBID_CONSUMER_KEY = clientConfig.TBID_CONSUMER_KEY;
export const TBID_LOGIN_AUTH_PROVIDER = clientConfig.TBID_LOGIN_AUTH_PROVIDER;
export const TBID_SIGNUP_AUTH_PROVIDER = clientConfig.TBID_SIGNUP_AUTH_PROVIDER;
export const LISTING_SERVICE_API_VERSION = clientConfig.LISTING_SERVICE_API_VERSION;
export const LISTING_SERVICE_URL = clientConfig.LISTING_SERVICE_URL;
export const ASSET_STORAGE_URL = clientConfig.ASSET_STORAGE_URL;
export const INVENTORY_API = clientConfig.INVENTORY_API;
export const USER_SERVICE_URL = clientConfig.USER_SERVICE_URL;
export const REVIEW_SERVICE_URL = clientConfig.REVIEW_SERVICE_URL;
export const RECOMMENDATION_SERVICE_URL = clientConfig.RECOMMENDATION_SERVICE_URL;
export const MARKETING_ANALYTICS_TARGET_URL = clientConfig.MARKETING_ANALYTICS_TARGET_URL;
export const ORG_API_TIMEOUT = clientConfig.ORG_API_TIMEOUT;
export const DEFAULT_API_TIMEOUT = clientConfig.DEFAULT_API_TIMEOUT;
export const PINK_DUCKS_URL = clientConfig.PINK_DUCKS_URL;

export const PINK_DUCKS_RATIOS = clientConfig.PINK_DUCKS_RATIOS;
export const ENABLE_PINK_DUCKS = clientConfig.ENABLE_PINK_DUCKS;
export const USE_STATIC_DATA = clientConfig.USE_STATIC_DATA;
export const IS_MFE_ACTIVE = clientConfig.IS_MFE_ACTIVE;
export const WORDPRESS_SERVICE_URL = clientConfig.WORDPRESS_SERVICE_URL;
export const TBID_MOCK_USER = clientConfig.TBID_MOCK_USER;
export const OAF_EXPLORE_URL = clientConfig.OAF_EXPLORE_URL;
export const APPEXCHANGE_URLS = clientConfig.APPEXCHANGE_URLS;

// Computed configs
export const IS_DEVELOPMENT = clientConfig.NODE_ENV === 'development';
export const IS_PRODUCTION = clientConfig.NODE_ENV === 'production';

// Unleash FeatureFlags
export const FEATURE_FLAG_PROXY_URL = clientConfig.FEATURE_FLAG_PROXY_URL;
export const FEATURE_FLAG_CLIENT_KEY = clientConfig.FEATURE_FLAG_CLIENT_KEY;
export const FEATURE_FLAG_APP_NAME = clientConfig.FEATURE_FLAG_APP_NAME;
export const FEATURE_FLAG_ENABLE_DEBUG_LOGS = clientConfig.FEATURE_FLAG_ENABLE_DEBUG_LOGS === 'true';

// Salesforce IDs
export const SALESFORCE_ORG_62_ACCOUNT_ID = '0013y00001d0OHtAAM';

// Cookies / Headers
export const COOKIE_LANGUAGE = 'ace_language';
export const NO_INDEX_HEADER = 'X-Robots-Tag';

// Favorites
export const FAVORITES_LIMIT = clientConfig.FAVORITES_LIMIT;
export const FAVORITES_LIMIT_PER_PAGE = clientConfig.FAVORITES_LIMIT_PER_PAGE;
export const BUCKETS = {
    EXPLORE_BUSINESS_NEEDS: 'business-needs',
    EXPLORE_PRODUCTS: 'products',
    EXPLORE_INDUSTRIES: 'industries',
    EXPLORE_LABS: 'salesforce-labs',
    EXPLORE_IMPACT: 'impact',
};

export const OFFERS_API_URL = clientConfig.OFFERS_API_URL;

// Sponsored
export const SPONSERED_RESULTS_LIMIT = clientConfig.SPONSERED_RESULTS_LIMIT;
